<template>
  <div style="min-height: 750px;">
    <b-row>
      <b-col sm="12" lg="2"></b-col>
      <b-col sm="12" lg="8"></b-col>
      <b-col sm="12" lg="2"></b-col>
    </b-row>
    <b-row style="margin-bottom: 20px; border-bottom: solid 1px whitesmoke;">
      <b-col sm="12" md="2" lg="2"> </b-col>
      <b-col sm="12" md="4" lg="4">
        <i class="fa fa-desktop"></i>
        {{ StoreLangTranslation.data['pay'][StoreLang] }}
      </b-col>
      <b-col sm="12" md="4" lg="4" :style="StoreDevice.isMobile ? '' : 'text-align: right;'"></b-col>
      <b-col sm="12" md="2" lg="2"></b-col>
    </b-row>
    <b-row style="font-size: 16px;">
      <b-col sm="12" lg="2"></b-col>
      <b-col sm="12" lg="8">
        <b-row>
          <b-col sm="12" lg="9">
            <b-card style="min-height: 300px;">
              <b-card-header header-bg-variant="white" class="p-1">
                <b-row style="margin: 3px;">
                  <b-col sm="12" lg="6" style="padding: 2px;">
                    <strong> {{ StoreLangTranslation.data['order_list'][StoreLang] }} </strong>
                  </b-col>
                  <b-col sm="12" lg="6" style="text-align: right;"></b-col>
                </b-row>
              </b-card-header>
                <b-row>
                  <b-col sm="12" lg="12">
                    <!-- 
                      d_shopOrderList = [
                        {
                          "product_list": [],
                          "user_shopcart_bundle_data": {},
                          "user_shop_bundle_data": {},
                        }
                      ]
                     -->
                     <b-card v-for="(shop_item, shop_item_index) in StoreShopCartList.data">
                      <b-card-header header-bg-variant="white" class="p-1">
                         <b-row style="margin: 0px; background-color: #93ffe4; padding: 5px;">
                          <b-col sm="12" lg="4">
                              <template v-if="shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['2'] && shop_item.shop_data.data['0']['2']['lang'] && shop_item.shop_data.data['0']['2']['lang'][StoreLang]">
                                <img :src="shop_item.shop_data.data['0']['2']['lang'][StoreLang]" style="width: 30px; margin-right: 3px;">
                              </template>
                              <template v-else-if="shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['2'] && shop_item.shop_data.data['0']['2']['val']">
                                <img :src="shop_item.shop_data.data['0']['2']['val']" style="width: 30px; margin-right: 3px;">
                              </template>

                              <template v-if="shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['1'] && shop_item.shop_data.data['0']['1']['lang'] && shop_item.shop_data.data['0']['1']['lang'][StoreLang]">
                                <strong :title="shop_item.shop_data.data['0']['1']['lang'][StoreLang]">{{ shop_item.shop_data.data['0']['1']['lang'][StoreLang].length > 30 ? shop_item.shop_data.data['0']['1']['lang'][StoreLang].substring(0,15) + '...' : shop_item.shop_data.data['0']['1']['lang'][StoreLang] }}</strong>
                              </template>
                              <template v-else-if="shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['1'] && shop_item.shop_data.data['0']['1']['val']">
                                <strong :title="shop_item.shop_data.data['0']['1']['val']">{{ shop_item.shop_data.data['0']['1']['val'].length > 30 ? shop_item.shop_data.data['0']['1']['val'].substring(0,15) + '...' : shop_item.shop_data.data['0']['1']['val'] }}</strong>
                              </template>
                          </b-col>
                          <b-col sm="12" lg="4">
                            <i class="fa fa-envelope" style="margin: 3px;"></i>

                              <template v-if="shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['29'] && shop_item.shop_data.data['0']['29']['lang'] && shop_item.shop_data.data['0']['29']['lang'][StoreLang]">
                                <strong>{{ shop_item.shop_data.data['0']['29']['lang'][StoreLang] }}</strong>
                              </template>
                              <template v-else-if="shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['29'] && shop_item.shop_data.data['0']['29']['val']">
                                <strong>{{ shop_item.shop_data.data['0']['29']['val'] }}</strong>
                              </template>
                          </b-col>
                          <b-col sm="12" lg="4">
                            <i class="fa fa-mobile-phone" style="margin: 3px;"></i>

                              <template v-if="shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['30'] && shop_item.shop_data.data['0']['30']['lang'] && shop_item.shop_data.data['0']['30']['lang'][StoreLang]">
                                <strong>{{ shop_item.shop_data.data['0']['30']['lang'][StoreLang] }}</strong>
                              </template>
                              <template v-else-if="shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['30'] && shop_item.shop_data.data['0']['30']['val']">
                                <strong>{{ shop_item.shop_data.data['0']['30']['val'] }}</strong>
                              </template>
                          </b-col>
                        </b-row>
                      </b-card-header>
                      <b-card-body>
                        <template v-for="(bundle_item, bundle_item_index) in shop_item.bundle_list" v-if="f_searchUserShopBundle(bundle_item)">
                           <b-row @mouseover="d_hoverIndex = bundle_item_index" @mouseleave="d_hoverIndex = ''" :style="StoreDevice.isMobile ? 'cursor: pointer; border-bottom: solid 1px #dcdcdc; font-weight: 900; background: #f7e48f;' : 'cursor: pointer; margin: 1px; border-bottom: solid 1px #dcdcdc; font-weight: 900; background: #f7e48f;'">
                            <b-col sm="12" lg="6">
                              <template v-if="bundle_item.user_shop_bundle_data.data && bundle_item.user_shop_bundle_data.data['0'] && bundle_item.user_shop_bundle_data.data['0']['1'] && bundle_item.user_shop_bundle_data.data['0']['1']['lang'] && bundle_item.user_shop_bundle_data.data['0']['1']['lang'][StoreLang]">
                                <span :style="d_hoverIndex === bundle_item_index ? ' color: rgb(252, 0, 153, 1);' : ''">{{ bundle_item.user_shop_bundle_data.data['0']['1']['lang'][StoreLang] }}</span>
                              </template>
                              <template v-else-if="bundle_item.user_shop_bundle_data.data && bundle_item.user_shop_bundle_data.data['0'] && bundle_item.user_shop_bundle_data.data['0']['1'] && bundle_item.user_shop_bundle_data.data['0']['1']['val']">
                                <span :style="d_hoverIndex === bundle_item_index ? ' color: rgb(252, 0, 153, 1);' : ''">{{ bundle_item.user_shop_bundle_data.data['0']['1']['val'] }}</span>
                              </template>
                            </b-col>
                            <b-col sm="12" lg="2">
                              {{ f_calculateProductTotalPrice(bundle_item.product_list) }}
                            </b-col>
                            <b-col sm="12" lg="2"></b-col>
                            <b-col sm="12" lg="2">
                              
                            </b-col>
                          </b-row>
                          <template v-for="(product_item, product_item_index) in bundle_item.product_list" v-if="f_showCheckProduct(product_item.product)">
                            <b-row :style="StoreDevice.isMobile ? 'cursor: pointer; border-bottom: solid 1px #dcdcdc; margin-left: 10px;' : 'cursor: pointer; margin: 1px; border-bottom: solid 1px #dcdcdc; margin-left: 10px;'">
                              <b-col sm="12" lg="8">
                                <i @click="f_openProductIngredients(product_item.product)" style="border-radius: 10px; padding-right: 5px; margin: 3px; padding-left: 5px; width: 20px; background-color: lightblue; text-align: center;">{{ (product_item_index + 1).toString() }}</i>
                                <template v-if="product_item.product.data && product_item.product.data['0'] && product_item.product.data['0']['1'] && product_item.product.data['0']['1']['lang'] && product_item.product.data['0']['1']['lang'][StoreLang]">
                                  <span>{{ product_item.product.data['0']['1']['lang'][StoreLang] }}</span>
                                </template>
                                <template v-else-if="product_item.product.data && product_item.product.data['0'] && product_item.product.data['0']['1'] && product_item.product.data['0']['1']['val']">
                                  <span>{{ product_item.product.data['0']['1']['val'] }}</span>
                                </template>
                              </b-col>
                              <b-col sm="12" lg="2"> {{ f_calculateProductTotalPrice([product_item]) }} </b-col>
                              <b-col sm="12" lg="2"></b-col>
                            </b-row>
                          </template>
                        </template>
                      </b-card-body>
                     </b-card>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm="12" lg="1">
                    <b-form-checkbox v-model="d_agreementApprove"></b-form-checkbox>
                  </b-col>
                  <b-col sm="12" lg="8">
                    <strong style="cursor: pointer; color: royalblue;" @click="d_agreementApprove ? d_agreementApprove = false : d_agreementApprove = true;">
                      {{ StoreLangTranslation.data['i_approve_agreement'][StoreLang] }}
                    </strong>
                  </b-col>
                  <b-col sm="12" lg="3">
                    <strong style="cursor: pointer; color: royalblue;" @click="f_agrementView()">
                      {{ StoreLangTranslation.data['agreement_preview'][StoreLang] }}
                    </strong>
                  </b-col>
                </b-row>
            </b-card>

            <div style="height: 20px"></div>

            <b-card>
              <b-card-header header-bg-variant="white" class="p-1">
                <b-row style="margin: 3px;">
                  <b-col sm="12" lg="6" style="padding: 2px;">
                    <strong>
                      {{ StoreLangTranslation.data['pay_with_money_transfer'][StoreLang] }}
                    </strong>
                  </b-col>
                  <b-col sm="12" lg="6" style="text-align: right;"></b-col>
                </b-row>
              </b-card-header>
               <b-card v-for="(shop_item, shop_item_index) in StoreShopCartList.data">
                <b-card-header header-bg-variant="white" class="p-1">
                  <b-row style="margin: 0px; background-color: #93ffe4; padding: 5px;">
                    <b-col sm="12" lg="4">
                        <template v-if="shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['2'] && shop_item.shop_data.data['0']['2']['lang'] && shop_item.shop_data.data['0']['2']['lang'][StoreLang]">
                          <img :src="shop_item.shop_data.data['0']['2']['lang'][StoreLang]" style="width: 30px; margin-right: 3px;">
                        </template>
                        <template v-else-if="shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['2'] && shop_item.shop_data.data['0']['2']['val']">
                          <img :src="shop_item.shop_data.data['0']['2']['val']" style="width: 30px; margin-right: 3px;">
                        </template>
                        <template v-if="shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['1'] && shop_item.shop_data.data['0']['1']['lang'] && shop_item.shop_data.data['0']['1']['lang'][StoreLang]">
                          <strong :title="shop_item.shop_data.data['0']['1']['lang'][StoreLang]">{{ shop_item.shop_data.data['0']['1']['lang'][StoreLang].length > 30 ? shop_item.shop_data.data['0']['1']['lang'][StoreLang].substring(0,15) + '...' : shop_item.shop_data.data['0']['1']['lang'][StoreLang] }}</strong>
                        </template>
                        <template v-else-if="shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['1'] && shop_item.shop_data.data['0']['1']['val']">
                          <strong :title="shop_item.shop_data.data['0']['1']['val']">{{ shop_item.shop_data.data['0']['1']['val'].length > 30 ? shop_item.shop_data.data['0']['1']['val'].substring(0,15) + '...' : shop_item.shop_data.data['0']['1']['val'] }}</strong>
                        </template>
                    </b-col>
                    <b-col sm="12" lg="4">
                      <i class="fa fa-envelope" style="margin: 3px;"></i>
                        <template v-if="shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['29'] && shop_item.shop_data.data['0']['29']['lang'] && shop_item.shop_data.data['0']['29']['lang'][StoreLang]">
                          <strong>{{ shop_item.shop_data.data['0']['29']['lang'][StoreLang] }}</strong>
                        </template>
                        <template v-else-if="shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['29'] && shop_item.shop_data.data['0']['29']['val']">
                          <strong>{{ shop_item.shop_data.data['0']['29']['val'] }}</strong>
                        </template>
                    </b-col>
                    <b-col sm="12" lg="4">
                      <i class="fa fa-mobile-phone" style="margin: 3px;"></i>
                        <template v-if="shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['30'] && shop_item.shop_data.data['0']['30']['lang'] && shop_item.shop_data.data['0']['30']['lang'][StoreLang]">
                          <strong>{{ shop_item.shop_data.data['0']['30']['lang'][StoreLang] }}</strong>
                        </template>
                        <template v-else-if="shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['30'] && shop_item.shop_data.data['0']['30']['val']">
                          <strong>{{ shop_item.shop_data.data['0']['30']['val'] }}</strong>
                        </template>
                    </b-col>
                  </b-row>
                </b-card-header>

                <b-card v-if="(shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['9'] && shop_item.shop_data.data['0']['9']['lang'] && shop_item.shop_data.data['0']['9']['lang'][StoreLang]) || (shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['9'] && shop_item.shop_data.data['0']['9']['val'])">
                  <b-row v-if="(shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['9'] && shop_item.shop_data.data['0']['9']['lang'] && shop_item.shop_data.data['0']['9']['lang'][StoreLang]) || (shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['9'] && shop_item.shop_data.data['0']['9']['val'])">
                    <b-col sm="12" lg="3">
                      {{ d_userShopWdm['0']['parameters']['9']['name']['translation'][StoreLang] }}
                    </b-col>
                    <b-col sm="12" lg="9">
                      <template v-if="shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['9'] && shop_item.shop_data.data['0']['9']['lang'] && shop_item.shop_data.data['0']['9']['lang'][StoreLang]">
                        <span>{{ shop_item.shop_data.data['0']['9']['lang'][StoreLang] }}</span>
                      </template>
                      <template v-else-if="shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['9'] && shop_item.shop_data.data['0']['9']['val']">
                        <span>{{ shop_item.shop_data.data['0']['9']['val'] }}</span>
                      </template>
                    </b-col>
                  </b-row>
                  <b-row v-if="(shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['10'] && shop_item.shop_data.data['0']['10']['lang'] && shop_item.shop_data.data['0']['10']['lang'][StoreLang]) || (shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['10'] && shop_item.shop_data.data['0']['10']['val'])">
                    <b-col sm="12" lg="3">
                      {{ d_userShopWdm['0']['parameters']['10']['name']['translation'][StoreLang] }}
                    </b-col>
                    <b-col sm="12" lg="9">
                      <template v-if="shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['10'] && shop_item.shop_data.data['0']['10']['lang'] && shop_item.shop_data.data['0']['10']['lang'][StoreLang]">
                        <span>{{ shop_item.shop_data.data['0']['10']['lang'][StoreLang] }}</span>
                      </template>
                      <template v-else-if="shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['10'] && shop_item.shop_data.data['0']['10']['val']">
                        <span>{{ shop_item.shop_data.data['0']['10']['val'] }}</span>
                      </template>
                    </b-col>
                  </b-row>
                  <b-row v-if="(shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['11'] && shop_item.shop_data.data['0']['11']['lang'] && shop_item.shop_data.data['0']['11']['lang'][StoreLang]) || (shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['11'] && shop_item.shop_data.data['0']['11']['val'])">
                    <b-col sm="12" lg="3">
                      {{ d_userShopWdm['0']['parameters']['11']['name']['translation'][StoreLang] }}
                    </b-col>
                    <b-col sm="12" lg="9">
                      <template v-if="shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['11'] && shop_item.shop_data.data['0']['11']['lang'] && shop_item.shop_data.data['0']['11']['lang'][StoreLang]">
                        <span>{{ shop_item.shop_data.data['0']['11']['lang'][StoreLang] }}</span>
                      </template>
                      <template v-else-if="shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['11'] && shop_item.shop_data.data['0']['11']['val']">
                        <span>{{ shop_item.shop_data.data['0']['11']['val'] }}</span>
                      </template>
                    </b-col>
                  </b-row>
                  <b-row v-if="(shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['12'] && shop_item.shop_data.data['0']['12']['lang'] && shop_item.shop_data.data['0']['12']['lang'][StoreLang]) || (shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['12'] && shop_item.shop_data.data['0']['12']['val'])">
                    <b-col sm="12" lg="3">
                      {{ d_userShopWdm['0']['parameters']['12']['name']['translation'][StoreLang] }}
                    </b-col>
                    <b-col sm="12" lg="9">
                      <template v-if="shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['12'] && shop_item.shop_data.data['0']['12']['lang'] && shop_item.shop_data.data['0']['12']['lang'][StoreLang]">
                        <span>{{ shop_item.shop_data.data['0']['12']['lang'][StoreLang] }}</span>
                      </template>
                      <template v-else-if="shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['12'] && shop_item.shop_data.data['0']['12']['val']">
                        <span>{{ shop_item.shop_data.data['0']['12']['val'] }}</span>
                      </template>
                    </b-col>
                  </b-row>
                </b-card>

                <div style="height: 10px"></div>

                <b-card v-if="(shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['13'] && shop_item.shop_data.data['0']['13']['lang'] && shop_item.shop_data.data['0']['13']['lang'][StoreLang]) || (shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['13'] && shop_item.shop_data.data['0']['13']['val'])">
                  <b-row v-if="(shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['13'] && shop_item.shop_data.data['0']['13']['lang'] && shop_item.shop_data.data['0']['13']['lang'][StoreLang]) || (shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['13'] && shop_item.shop_data.data['0']['13']['val'])">
                    <b-col sm="12" lg="3">
                      {{ d_userShopWdm['0']['parameters']['13']['name']['translation'][StoreLang] }}
                    </b-col>
                    <b-col sm="12" lg="9">
                      <template v-if="shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['13'] && shop_item.shop_data.data['0']['13']['lang'] && shop_item.shop_data.data['0']['13']['lang'][StoreLang]">
                        <span>{{ shop_item.shop_data.data['0']['13']['lang'][StoreLang] }}</span>
                      </template>
                      <template v-else-if="shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['13'] && shop_item.shop_data.data['0']['13']['val']">
                        <span>{{ shop_item.shop_data.data['0']['13']['val'] }}</span>
                      </template>
                    </b-col>
                  </b-row>
                  <b-row v-if="(shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['14'] && shop_item.shop_data.data['0']['14']['lang'] && shop_item.shop_data.data['0']['14']['lang'][StoreLang]) || (shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['14'] && shop_item.shop_data.data['0']['14']['val'])">
                    <b-col sm="12" lg="3">
                      {{ d_userShopWdm['0']['parameters']['14']['name']['translation'][StoreLang] }}
                    </b-col>
                    <b-col sm="12" lg="9">
                      <template v-if="shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['14'] && shop_item.shop_data.data['0']['14']['lang'] && shop_item.shop_data.data['0']['14']['lang'][StoreLang]">
                        <span>{{ shop_item.shop_data.data['0']['14']['lang'][StoreLang] }}</span>
                      </template>
                      <template v-else-if="shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['14'] && shop_item.shop_data.data['0']['14']['val']">
                        <span>{{ shop_item.shop_data.data['0']['14']['val'] }}</span>
                      </template>
                    </b-col>
                  </b-row>
                  <b-row v-if="(shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['15'] && shop_item.shop_data.data['0']['15']['lang'] && shop_item.shop_data.data['0']['15']['lang'][StoreLang]) || (shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['15'] && shop_item.shop_data.data['0']['15']['val'])">
                    <b-col sm="12" lg="3">
                      {{ d_userShopWdm['0']['parameters']['15']['name']['translation'][StoreLang] }}
                    </b-col>
                    <b-col sm="12" lg="9">
                      <template v-if="shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['15'] && shop_item.shop_data.data['0']['15']['lang'] && shop_item.shop_data.data['0']['15']['lang'][StoreLang]">
                        <span>{{ shop_item.shop_data.data['0']['15']['lang'][StoreLang] }}</span>
                      </template>
                      <template v-else-if="shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['15'] && shop_item.shop_data.data['0']['15']['val']">
                        <span>{{ shop_item.shop_data.data['0']['15']['val'] }}</span>
                      </template>
                    </b-col>
                  </b-row>
                  <b-row v-if="(shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['16'] && shop_item.shop_data.data['0']['16']['lang'] && shop_item.shop_data.data['0']['16']['lang'][StoreLang]) || (shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['16'] && shop_item.shop_data.data['0']['16']['val'])">
                    <b-col sm="12" lg="3">
                      {{ d_userShopWdm['0']['parameters']['16']['name']['translation'][StoreLang] }}
                    </b-col>
                    <b-col sm="12" lg="9">
                      <template v-if="shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['16'] && shop_item.shop_data.data['0']['16']['lang'] && shop_item.shop_data.data['0']['16']['lang'][StoreLang]">
                        <span>{{ shop_item.shop_data.data['0']['16']['lang'][StoreLang] }}</span>
                      </template>
                      <template v-else-if="shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['16'] && shop_item.shop_data.data['0']['16']['val']">
                        <span>{{ shop_item.shop_data.data['0']['16']['val'] }}</span>
                      </template>
                    </b-col>
                  </b-row>
                </b-card>

                <div style="height: 10px"></div>

                <b-card v-if="(shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['17'] && shop_item.shop_data.data['0']['17']['lang'] && shop_item.shop_data.data['0']['17']['lang'][StoreLang]) || (shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['17'] && shop_item.shop_data.data['0']['17']['val'])">
                  <b-row v-if="(shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['17'] && shop_item.shop_data.data['0']['17']['lang'] && shop_item.shop_data.data['0']['17']['lang'][StoreLang]) || (shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['17'] && shop_item.shop_data.data['0']['17']['val'])">
                    <b-col sm="12" lg="3">
                      {{ d_userShopWdm['0']['parameters']['17']['name']['translation'][StoreLang] }}
                    </b-col>
                    <b-col sm="12" lg="9">
                      <template v-if="shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['17'] && shop_item.shop_data.data['0']['17']['lang'] && shop_item.shop_data.data['0']['17']['lang'][StoreLang]">
                        <span>{{ shop_item.shop_data.data['0']['17']['lang'][StoreLang] }}</span>
                      </template>
                      <template v-else-if="shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['17'] && shop_item.shop_data.data['0']['17']['val']">
                        <span>{{ shop_item.shop_data.data['0']['17']['val'] }}</span>
                      </template>
                    </b-col>
                  </b-row>
                  <b-row v-if="(shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['18'] && shop_item.shop_data.data['0']['18']['lang'] && shop_item.shop_data.data['0']['18']['lang'][StoreLang]) || (shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['18'] && shop_item.shop_data.data['0']['18']['val'])">
                    <b-col sm="12" lg="3">
                      {{ d_userShopWdm['0']['parameters']['18']['name']['translation'][StoreLang] }}
                    </b-col>
                    <b-col sm="12" lg="9">
                      <template v-if="shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['18'] && shop_item.shop_data.data['0']['18']['lang'] && shop_item.shop_data.data['0']['18']['lang'][StoreLang]">
                        <span>{{ shop_item.shop_data.data['0']['18']['lang'][StoreLang] }}</span>
                      </template>
                      <template v-else-if="shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['18'] && shop_item.shop_data.data['0']['18']['val']">
                        <span>{{ shop_item.shop_data.data['0']['18']['val'] }}</span>
                      </template>
                    </b-col>
                  </b-row>
                  <b-row v-if="(shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['19'] && shop_item.shop_data.data['0']['19']['lang'] && shop_item.shop_data.data['0']['19']['lang'][StoreLang]) || (shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['19'] && shop_item.shop_data.data['0']['19']['val'])">
                    <b-col sm="12" lg="3">
                      {{ d_userShopWdm['0']['parameters']['19']['name']['translation'][StoreLang] }}
                    </b-col>
                    <b-col sm="12" lg="9">
                      <template v-if="shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['19'] && shop_item.shop_data.data['0']['19']['lang'] && shop_item.shop_data.data['0']['19']['lang'][StoreLang]">
                        <span>{{ shop_item.shop_data.data['0']['19']['lang'][StoreLang] }}</span>
                      </template>
                      <template v-else-if="shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['19'] && shop_item.shop_data.data['0']['19']['val']">
                        <span>{{ shop_item.shop_data.data['0']['19']['val'] }}</span>
                      </template>
                    </b-col>
                  </b-row>
                  <b-row v-if="(shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['20'] && shop_item.shop_data.data['0']['20']['lang'] && shop_item.shop_data.data['0']['20']['lang'][StoreLang]) || (shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['20'] && shop_item.shop_data.data['0']['20']['val'])">
                    <b-col sm="12" lg="3">
                      {{ d_userShopWdm['0']['parameters']['20']['name']['translation'][StoreLang] }}
                    </b-col>
                    <b-col sm="12" lg="9">
                      <template v-if="shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['20'] && shop_item.shop_data.data['0']['20']['lang'] && shop_item.shop_data.data['0']['20']['lang'][StoreLang]">
                        <span>{{ shop_item.shop_data.data['0']['20']['lang'][StoreLang] }}</span>
                      </template>
                      <template v-else-if="shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['20'] && shop_item.shop_data.data['0']['20']['val']">
                        <span>{{ shop_item.shop_data.data['0']['20']['val'] }}</span>
                      </template>
                    </b-col>
                  </b-row>
                </b-card>

                <div style="height: 10px"></div>

                <b-card v-if="(shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['21'] && shop_item.shop_data.data['0']['21']['lang'] && shop_item.shop_data.data['0']['21']['lang'][StoreLang]) || (shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['21'] && shop_item.shop_data.data['0']['21']['val'])">
                  <b-row v-if="(shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['21'] && shop_item.shop_data.data['0']['21']['lang'] && shop_item.shop_data.data['0']['21']['lang'][StoreLang]) || (shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['21'] && shop_item.shop_data.data['0']['21']['val'])">
                    <b-col sm="12" lg="3">
                      {{ d_userShopWdm['0']['parameters']['21']['name']['translation'][StoreLang] }}
                    </b-col>
                    <b-col sm="12" lg="9">
                      <template v-if="shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['21'] && shop_item.shop_data.data['0']['21']['lang'] && shop_item.shop_data.data['0']['21']['lang'][StoreLang]">
                        <span>{{ shop_item.shop_data.data['0']['21']['lang'][StoreLang] }}</span>
                      </template>
                      <template v-else-if="shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['21'] && shop_item.shop_data.data['0']['21']['val']">
                        <span>{{ shop_item.shop_data.data['0']['21']['val'] }}</span>
                      </template>
                    </b-col>
                  </b-row>
                  <b-row v-if="(shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['22'] && shop_item.shop_data.data['0']['22']['lang'] && shop_item.shop_data.data['0']['22']['lang'][StoreLang]) || (shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['22'] && shop_item.shop_data.data['0']['22']['val'])">
                    <b-col sm="12" lg="3">
                      {{ d_userShopWdm['0']['parameters']['22']['name']['translation'][StoreLang] }}
                    </b-col>
                    <b-col sm="12" lg="9">
                      <template v-if="shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['22'] && shop_item.shop_data.data['0']['22']['lang'] && shop_item.shop_data.data['0']['22']['lang'][StoreLang]">
                        <span>{{ shop_item.shop_data.data['0']['22']['lang'][StoreLang] }}</span>
                      </template>
                      <template v-else-if="shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['22'] && shop_item.shop_data.data['0']['22']['val']">
                        <span>{{ shop_item.shop_data.data['0']['22']['val'] }}</span>
                      </template>
                    </b-col>
                  </b-row>
                  <b-row v-if="(shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['23'] && shop_item.shop_data.data['0']['23']['lang'] && shop_item.shop_data.data['0']['23']['lang'][StoreLang]) || (shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['23'] && shop_item.shop_data.data['0']['23']['val'])">
                    <b-col sm="12" lg="3">
                      {{ d_userShopWdm['0']['parameters']['23']['name']['translation'][StoreLang] }}
                    </b-col>
                    <b-col sm="12" lg="9">
                      <template v-if="shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['23'] && shop_item.shop_data.data['0']['23']['lang'] && shop_item.shop_data.data['0']['23']['lang'][StoreLang]">
                        <span>{{ shop_item.shop_data.data['0']['23']['lang'][StoreLang] }}</span>
                      </template>
                      <template v-else-if="shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['23'] && shop_item.shop_data.data['0']['23']['val']">
                        <span>{{ shop_item.shop_data.data['0']['23']['val'] }}</span>
                      </template>
                    </b-col>
                  </b-row>
                  <b-row v-if="(shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['24'] && shop_item.shop_data.data['0']['24']['lang'] && shop_item.shop_data.data['0']['24']['lang'][StoreLang]) || (shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['24'] && shop_item.shop_data.data['0']['24']['val'])">
                    <b-col sm="12" lg="3">
                      {{ d_userShopWdm['0']['parameters']['24']['name']['translation'][StoreLang] }}
                    </b-col>
                    <b-col sm="12" lg="9">
                      <template v-if="shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['24'] && shop_item.shop_data.data['0']['24']['lang'] && shop_item.shop_data.data['0']['24']['lang'][StoreLang]">
                        <span>{{ shop_item.shop_data.data['0']['24']['lang'][StoreLang] }}</span>
                      </template>
                      <template v-else-if="shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['24'] && shop_item.shop_data.data['0']['24']['val']">
                        <span>{{ shop_item.shop_data.data['0']['24']['val'] }}</span>
                      </template>
                    </b-col>
                  </b-row>
                </b-card>

                <div style="height: 10px"></div>

                <b-card v-if="(shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['25'] && shop_item.shop_data.data['0']['25']['lang'] && shop_item.shop_data.data['0']['25']['lang'][StoreLang]) || (shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['25'] && shop_item.shop_data.data['0']['25']['val'])">
                  <b-row v-if="(shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['25'] && shop_item.shop_data.data['0']['25']['lang'] && shop_item.shop_data.data['0']['25']['lang'][StoreLang]) || (shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['25'] && shop_item.shop_data.data['0']['25']['val'])">
                    <b-col sm="12" lg="3">
                      {{ d_userShopWdm['0']['parameters']['25']['name']['translation'][StoreLang] }}
                    </b-col>
                    <b-col sm="12" lg="9">
                      <template v-if="shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['25'] && shop_item.shop_data.data['0']['25']['lang'] && shop_item.shop_data.data['0']['25']['lang'][StoreLang]">
                        <span>{{ shop_item.shop_data.data['0']['25']['lang'][StoreLang] }}</span>
                      </template>
                      <template v-else-if="shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['25'] && shop_item.shop_data.data['0']['25']['val']">
                        <span>{{ shop_item.shop_data.data['0']['25']['val'] }}</span>
                      </template>
                    </b-col>
                  </b-row>
                  <b-row v-if="(shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['26'] && shop_item.shop_data.data['0']['26']['lang'] && shop_item.shop_data.data['0']['26']['lang'][StoreLang]) || (shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['26'] && shop_item.shop_data.data['0']['26']['val'])">
                    <b-col sm="12" lg="3">
                      {{ d_userShopWdm['0']['parameters']['26']['name']['translation'][StoreLang] }}
                    </b-col>
                    <b-col sm="12" lg="9">
                      <template v-if="shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['26'] && shop_item.shop_data.data['0']['26']['lang'] && shop_item.shop_data.data['0']['26']['lang'][StoreLang]">
                        <span>{{ shop_item.shop_data.data['0']['26']['lang'][StoreLang] }}</span>
                      </template>
                      <template v-else-if="shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['26'] && shop_item.shop_data.data['0']['26']['val']">
                        <span>{{ shop_item.shop_data.data['0']['26']['val'] }}</span>
                      </template>
                    </b-col>
                  </b-row>
                  <b-row v-if="(shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['27'] && shop_item.shop_data.data['0']['27']['lang'] && shop_item.shop_data.data['0']['27']['lang'][StoreLang]) || (shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['27'] && shop_item.shop_data.data['0']['27']['val'])">
                    <b-col sm="12" lg="3">
                      {{ d_userShopWdm['0']['parameters']['27']['name']['translation'][StoreLang] }}
                    </b-col>
                    <b-col sm="12" lg="9">
                      <template v-if="shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['27'] && shop_item.shop_data.data['0']['27']['lang'] && shop_item.shop_data.data['0']['27']['lang'][StoreLang]">
                        <span>{{ shop_item.shop_data.data['0']['27']['lang'][StoreLang] }}</span>
                      </template>
                      <template v-else-if="shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['27'] && shop_item.shop_data.data['0']['27']['val']">
                        <span>{{ shop_item.shop_data.data['0']['27']['val'] }}</span>
                      </template>
                    </b-col>
                  </b-row>
                  <b-row v-if="(shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['28'] && shop_item.shop_data.data['0']['28']['lang'] && shop_item.shop_data.data['0']['28']['lang'][StoreLang]) || (shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['28'] && shop_item.shop_data.data['0']['28']['val'])">
                    <b-col sm="12" lg="3">
                      {{ d_userShopWdm['0']['parameters']['28']['name']['translation'][StoreLang] }}
                    </b-col>
                    <b-col sm="12" lg="9">
                      <template v-if="shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['28'] && shop_item.shop_data.data['0']['28']['lang'] && shop_item.shop_data.data['0']['28']['lang'][StoreLang]">
                        <span>{{ shop_item.shop_data.data['0']['28']['lang'][StoreLang] }}</span>
                      </template>
                      <template v-else-if="shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['28'] && shop_item.shop_data.data['0']['28']['val']">
                        <span>{{ shop_item.shop_data.data['0']['28']['val'] }}</span>
                      </template>
                    </b-col>
                  </b-row>
                </b-card>

              </b-card>
              <b-row>
                <b-col sm="12" lg="12">
                  <b-row>
                    <b-col sm="12" lg=8></b-col>
                    <b-col sm="12" lg=4>
                      <b-button variant="success" style="width: 100%;" v-on:click="f_openOrderModal()"> {{ StoreLangTranslation.data['order_create'][StoreLang] }} </b-button>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-card>

            <div style="height: 20px"></div>

            <b-card v-if="false">
              <b-card-header header-bg-variant="white" class="p-1">
                <b-row style="margin: 3px;">
                  <b-col sm="12" lg="6" style="padding: 2px;">
                    <strong>
                      {{ StoreLangTranslation.data['pay_with_credit_card'][StoreLang] }}
                    </strong>
                  </b-col>
                  <b-col sm="12" lg="6" style="text-align: right;"></b-col>
                </b-row>
              </b-card-header>
              <b-row>
                <b-col sm="12" lg="12">
                  <CardForm :form-data="formData" :p_paymentApprovedInfo="d_paymentApprovedInfo" :f_payWithCreditCard="f_payWithCreditCard" @input-card-number="updateCardNumber" @input-card-name="updateCardName" @input-card-month="updateCardMonth" @input-card-year="updateCardYear" @input-card-cvv="updateCardCvv" />
                </b-col>
              </b-row>
            </b-card>

          </b-col>
          <b-col sm="12" lg="3">
            <b-card>
              <b-card-header header-bg-variant="white" class="p-1">
                {{ StoreLangTranslation.data['order_brief'][StoreLang] }}
              </b-card-header>
              <b-row>
                <b-col sm="12" lg="5">
                  {{ StoreLangTranslation.data['product_count'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="1"> : </b-col>
                <b-col sm="12" lg="5">
                  {{ f_calculateShopcartBundleCount() }}
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="12" lg="5">
                  {{ StoreLangTranslation.data['total'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="1"> : </b-col>
                <b-col sm="12" lg="5">
                  {{ f_calculateAllBundleTotalPrice() }}
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
      <b-col sm="12" lg="2"></b-col>
    </b-row>
    <b-modal v-if="d_orderDetails.show_modal" id="d_orderDetails_show_modal" v-model="d_orderDetails.show_modal" scrollable hide-footer :title="StoreLangTranslation.data['order_create'][StoreLang]" centered header-bg-variant="info" header-text-variant="dark" size="lg">
      <p class="my-4" style="word-wrap: break-word;">
        <i>
          {{ StoreLangTranslation.data['order_info'][StoreLang] }}
        </i>
      </p>
      <p>
      <b-row>
          <b-col sm="12" lg="6"></b-col>
          <b-col sm="12" lg="6" style="text-align: right;">
          <template v-if="d_loading">
            <b-spinner variant="primary" label="Spinning"></b-spinner>
          </template>
          <template v-else>
            <b-button variant="success" @click="f_userShopcartBundleProductOrder()"> {{ StoreLangTranslation.data['order_approve'][StoreLang] }} </b-button>
          </template>
          </b-col>
        </b-row>
      </p>
    </b-modal>
    <b-modal v-if="d_agreementModal.show" id="d_agreementModal_show" v-model="d_agreementModal.show" scrollable hide-footer :title="StoreLangTranslation.data['agreement_preview'][StoreLang]" centered header-bg-variant="info" header-text-variant="dark" size="lg">
      <p>
        <template v-for="(shop_item, shop_item_index) in StoreShopCartList.data">
          <b-row v-for="(bundle_item, bundle_item_index) in shop_item.bundle_list">
            <b-col sm="12" lg="12">
              <agreement-view :key="bundle_item.user_shop_bundle_data.client_id" :p_shopData="{'client_id': shop_item.shop_data.client_id}" :p_bundleData="{'client_id': bundle_item.user_shop_bundle_data.client_id}" :p_showMode="{'mode': 'approve'}"></agreement-view>
            </b-col>
          </b-row>
        </template>
      </p>
    </b-modal>
  </div>
</template>

<script>
import { data as system_menu_list } from '@/options/system_menu_list';
import store from '@/store';
import UserShopService from '@/services/user_shop';
import { data as usr_shp_bund } from '@/options/usr_shp_bund';
import { data as usr_shpcrt_bund } from '@/options/usr_shpcrt_bund';
import { data as usr_shpcrt_bund_pro } from '@/options/usr_shpcrt_bund_pro';
import { data as usr_shp } from '@/options/usr_shp';
import {
  default as AgreementView
} from '@/wam/components/widgets/AgreementView';
import {
  default as CardForm
} from "@/components/widgets/CardForm";
import {
  default as PageInfo
} from '@/components/widgets/PageInfo';
import { mapGetters } from 'vuex';
export default {
  name: 'user_shoppingcart_payment',
  computed: mapGetters({
    StoreDevice: 'StoreDevice',
    StoreLang: 'StoreLang',
    StoreLangList: 'StoreLangList',
    StoreLangTranslation: 'StoreLangTranslation',
    StoreFrontendSettings: 'StoreFrontendSettings',
    StoreShopCartList: 'StoreShopCartList',
    StoreShopCartOrderList: 'StoreShopCartOrderList',
    StoreCurrencyType: 'StoreCurrencyType',
  }),
  components: {
    PageInfo,
    CardForm,
    AgreementView,
  },
  props: {},
  data () {
    return {
      d_loading: false,
      d_agreementModal: {
        'show': false
      },
      d_agreementApprove: false,
      d_userShopData: '',
      d_orderDetails: {
        'show_modal': false,
      },
      formData: {
        cardName: '',
        cardNumber: '',
        cardNumberNotMask: '',
        cardMonth: '',
        cardYear: '',
        cardCvv: ''
      },
      d_paymentApprovedInfo: { 'approved': 0 },
      user: {},
      d_selectedPayment: {},
      d_show: false,
      d_paymentModalData: { 'show': 0, 'html': '' },
      d_currencyType: '0', // 0 tl, 1 dollar
      d_user: '',
      d_hoverIndex: '',
      d_searchText: '',
      d_frontendClientID: '',
      d_frontendList: [],
      d_usrShpBundleList: [],
      d_usrShpBundWdm: usr_shp_bund,
      d_usrShpcrtBundWdm: usr_shpcrt_bund,
      d_usrShpcrtBundProWdm: usr_shpcrt_bund_pro,
      d_userShopWdm: usr_shp,
    };
  },
  created: function () {
    // console.log('this.StoreShopCartList ', this.StoreShopCartList);
    this.d_user = JSON.parse(localStorage.getItem('user'));
  },
  beforeMount () {},
  mounted: function () {
    // console.log(this.StoreFrontendSettings);
    // console.log(this.d_usrShpBundWdm);
  },
  methods: {
    f_showCheckProduct: function (product_data) {
      let show_product = true;
      try {
        if (product_data.data['0']['48']['val']['value'] === '0') {
          show_product = false;
        }
      } catch(err) {}
      return show_product;
    },
    f_agrementView: function () {
      this.d_agreementModal.show = true;
    },
    f_getOptionLabel: function (option_value, options) {
      // console.log('option_value : ', option_value);
      // console.log('options : ', options);
      let option_label = '';
      for (let i in options) {
        if (options[i].value === option_value) {
          option_label = options[i].translation[this.StoreLang];
          break;
        }
      }
      return option_label;
    },
    f_calculateShopcartBundleCount: function () {
      let total_count = 0;
      for (let i in this.StoreShopCartList.data) {
        total_count += this.StoreShopCartList.data[i].bundle_list.length;
      }
      return total_count;
    },
    f_openOrderModal () {
      if (this.d_agreementApprove) {
        this.d_orderDetails.show_modal = true;
      } else {
        alert(this.StoreLangTranslation.data['please_read_and_approve_agreements'][this.StoreLang]);
      }
    },
    updateCardNumber (val) {},
    updateCardName (val) {},
    updateCardMonth (val) {},
    updateCardYear (val) {},
    updateCardCvv (val) {},    
    f_getRoutePath: function (target_route_name_en) {
      let route_path = '';
      for (let i in system_menu_list) {
        if (system_menu_list[i].route_name.en == target_route_name_en) {
          route_path = system_menu_list[i].route_path[this.StoreLang];
        }
        if (!route_path) {
          if (system_menu_list[i].list && system_menu_list[i].list.length) {
            for (let k in system_menu_list[i].list) {
              if (system_menu_list[i].list[k].route_name.en == target_route_name_en) {
                route_path = system_menu_list[i].list[k].route_path[this.StoreLang];
                break;
              }
            }
          }
        }
        if (route_path) {
          break;
        }
      }
      return route_path;
    },
    f_newUserShopBundle: function () {
      this.$router.push({ path: this.f_getRoutePath('user_shop_bundle_record'), query: {} });
    },
    f_goToUserShopBundleProductListPage: function (user_shop_bundle_client_id) {
      this.$router.push({ path: this.f_getRoutePath('user_shop_bundle_product_list'), query: {'user_shop_client_id': this.d_userShopClientID, 'user_shop_bundle_client_id': user_shop_bundle_client_id} });
    },
    f_editThisUserShopBundle: function (user_shop_bundle_client_id) {
      let route_path = this.f_getRoutePath('user_shop_bundle_record');
      // console.log('route_path : ', route_path);
      this.$router.push({ path: route_path, query: { 'user_shop_bundle_client_id': user_shop_bundle_client_id, 'user_shop_client_id': this.d_userShopClientID } });
    },
    f_searchUserShopBundle: function (usr_shp_bund) {
      let res = false;
      if (this.d_searchText) {
        if (usr_shp_bund.w_id.toLocaleLowerCase().indexOf(this.d_searchText.toLocaleLowerCase()) !== -1) {
          res = true;
        }
      } else {
        res = true;
      }
      return res;
    },
    f_userShopBundleList: function () {
      let query = 'user_client_id=' + this.d_user.username;
      query += '&user_shop_client_id=' + this.d_userShopClientID;
      let data = {};
      UserShopService.user_shop_bundle_list(query, data)
        .then(resp => {
          if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
            if (resp.data.list) {
              this.d_usrShpBundleList = resp.data.list;
            } else {
              alert(this.StoreLangTranslation.data['process_completed'][this.StoreLang]);
            }
          } else {
            alert(resp.data.status_code, ' ', resp.data.status_message);
          }
        });
    },
    f_userShopcartBundleProductOrder: function () {
      if (this.d_agreementApprove) {
        this.d_loading = true;
        let query = 'user_client_id=' + this.d_user.username;
        let data = {};
        UserShopService.user_shopcart_bundle_product_order(query, data)
          .then(resp => {
            this.d_loading = false;
            if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
              if (resp.data.order_status === 'completed') {
                this.d_orderDetails.show_modal = false;
                // console.log('resp.data : ', resp.data);
                // alert(this.StoreLangTranslation.data['process_completed'][this.StoreLang]);
                if (this.StoreShopCartList.status === 1) {
                  this.$store.commit('MutationShopCartList', { 'status': 0, 'data': this.StoreShopCartList.data });
                } else {
                  this.$store.commit('MutationShopCartList', { 'status': 1, 'data': this.StoreShopCartList.data });
                }
                if (this.StoreShopCartList.status === 1) {
                  this.$store.commit('MutationShopCartOrderList', { 'status': 0, 'data': this.StoreShopCartOrderList.data });
                } else {
                  this.$store.commit('MutationShopCartOrderList', { 'status': 1, 'data': this.StoreShopCartOrderList.data });
                }
                this.$router.push({ path: this.f_getRoutePath('user_shopcart_bundle_order_list'), query: {} });
              }
            } else {
              alert(resp.data.status_code, ' ', resp.data.status_message);
            }
          });
      } else {
        alert(this.StoreLangTranslation.data['please_read_and_approve_agreements'][this.StoreLang]);
      }
    },
    f_payWithCreditCard: function () {
      if (this.d_agreementApprove) {
        alert(this.StoreLangTranslation.data['developing'][this.StoreLang]);
      } else {
        alert(this.StoreLangTranslation.data['please_read_and_approve_agreements'][this.StoreLang]);
      }
    },
    f_calculateBundleTotalPrice: function (bundle_products) {
      /*
        Önceden dile göre para birimi kararı veriliyordu. Artık para birimi de headerdan seçiliyor.
        if (this.StoreLang === 'tr') {
          this.StoreCurrencyType.value = '0';
        } else if (this.StoreLang === 'en') {
          this.StoreCurrencyType.value = '1';
        }
      */
      let currency_text = this.StoreCurrencyType.label;
      let total_amount = 0;
      for (let product_id in bundle_products.parameters) {
        try {
          for (let currency_amount_index in bundle_products.parameters[product_id].data.data['0']['25'].list) {
            let currency_amount_item = bundle_products.parameters[product_id].data.data['0']['25'].list[currency_amount_index];
            try {
              if (currency_amount_item['26']['val']['value'] === this.StoreCurrencyType.value) {
                total_amount += parseFloat(currency_amount_item['27']['val']);
              }
            } catch(err) {}
          }
        } catch(err) {}
      }
      return total_amount + ' ' + currency_text;
    },
    f_calculateAllBundleTotalPrice: function () {
      let currency_text = this.StoreCurrencyType.label;
      let total_amount = 0;
      for (let shop_index in this.StoreShopCartList.data) {
        for (let bundle_index in this.StoreShopCartList.data[shop_index].bundle_list) {
          for (let product_index in this.StoreShopCartList.data[shop_index].bundle_list[bundle_index].product_list) {
            try {
              for (let currency_amount_index in this.StoreShopCartList.data[shop_index].bundle_list[bundle_index].product_list[product_index].product.data['0']['25'].list) {
                let currency_amount_item = this.StoreShopCartList.data[shop_index].bundle_list[bundle_index].product_list[product_index].product.data['0']['25'].list[currency_amount_index];
                try {
                  if (currency_amount_item['26']['val']['value'] === this.StoreCurrencyType.value) {
                    total_amount += parseFloat(currency_amount_item['27']['val']);
                  }
                } catch(err) {console.log(err)}
              }
            } catch(err) {console.log(err)}
          }
        }
      }
      return total_amount + ' ' + currency_text;
    },
    f_calculateProductTotalPrice: function (bundle_products) {
      let currency_text = this.StoreCurrencyType.label;
      let total_amount = 0;
      for (let product_index in bundle_products) {
        try {
          for (let currency_amount_index in bundle_products[product_index].product.data['0']['25'].list) {
            let currency_amount_item = bundle_products[product_index].product.data['0']['25'].list[currency_amount_index];
            try {
              if (currency_amount_item['26']['val']['value'] === this.StoreCurrencyType.value) {
                total_amount += parseFloat(currency_amount_item['27']['val']);
              }
            } catch(err) {}
          }
        } catch(err) {}
      }
      return total_amount + ' ' + currency_text;
    },
  },
  watch: {}
};

</script>

